@media only screen and (max-width: 600px) {
   
}

@media only screen and (min-width: 992px) {
    .car-wrapper {
        width: 350px;
        height: 350px;
        margin: 0 auto;
    }
}