
@media screen and (max-width: 991px) {
    .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }
    .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }
    .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }
    .customized-timeline .p-card {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .prize-wrapper {
        flex-direction: column;
    }
}
    